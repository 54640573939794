<template>
<div class="app flex-row align-items-center container-wrapper" :style="container_background_image">
    <div class="container p-3">
        <b-row class="m-0 justify-content-center shadow-2">
            <b-col md="6" class="p-0 bg-white">
                <q-card no-body stretch class="bg-primary full-height d-flex wrap register-side text-center align-items-center">
                    <div class="full-width align-self-start">
                        <div class="full-width navbar-brand mb-4 mt-4">
                            <img class="navbar-brand-full" :src="appLogo()" height="100" alt="Dynamic Studio Logo">
                        </div>
                        <q-separator class="m-0" dark />
                    </div>
                    <div class="full-width pb-4 align-self-end text-white">
                        <h2 class="mt-4 mb-4 text-h4">{{ translate('welcome') }}</h2>
                        <q-btn icon-right="send" color="danger" square no-caps :to="{name: 'Register'}" type="button" class="no-text-decoration bg-brand-color-red icon-right-leftspace">{{ translate('register') }}</q-btn>
                        <q-btn icon-right="login" color="dark" square no-caps :to="{name: 'Login'}" type="button" class="ml-1 no-text-decoration icon-right-leftspace">{{ translate('login') }}</q-btn>
                    </div>
                </q-card>
            </b-col>
            <b-col md="6" class="p-0 bg-white">
                <q-card no-body class="p-4 full-height register-form" light>
                    <AppHeader class="header-lang pl-3 pr-1 mb-4" :style="{position: 'relative', marginTop: '-1rem'}">
                        <div class="text-muted font-weight-bold">{{ translate('selected_language') }}</div>
                        <switch-lang></switch-lang>
                    </AppHeader>
                    <b-form>
                        <h1 class="text-h4 text-uppercase text-center font-weight-bold text-primary">{{ translate('group_invite') }}</h1>
                        <p class="text-center text-muted">{{ translate('respond_to_group_invitation') }}</p>
                        <template v-if="!$q.screen.lt.sm">
                            <div class="full-width text-center text-white">
                                <q-btn @click.prevent="respondToInvitation(true)" icon-right="check" color="positive" square no-caps type="button" class="no-text-decoration icon-right-leftspace">{{ translate('accept_invitation') }}</q-btn>
                                <q-btn @click.prevent="respondToInvitation(false)" icon-right="close" color="negative" square no-caps type="button" class="ml-1 no-text-decoration bg-brand-color-red icon-right-leftspace">{{ translate('reject_invitation') }}</q-btn>
                            </div>
                        </template>
                        <template v-else>
                            <div class="full-width pb-2 text-center text-white">
                                <q-btn @click.prevent="respondToInvitation(true)" icon-right="check" color="positive" square no-caps type="button" class="no-text-decoration icon-right-leftspace full-width">{{ translate('accept_invitation') }}</q-btn>
                            </div>
                            <div class="full-width pb-0 text-center text-white">
                                <q-btn @click.prevent="respondToInvitation(false)" icon-right="close" color="negative" square no-caps type="button" class="no-text-decoration bg-brand-color-red icon-right-leftspace full-width">{{ translate('reject_invitation') }}</q-btn>
                            </div>
                        </template>
                        <div v-if="msg" class="mt-4">
                            <q-item :class="msg_status === true ? 'bg-green-2 border-top-green' : 'bg-red-2 border-top-red'">
                                <q-item-section :class="msg_status === true ? 'text-green-8' : 'is-invalid text-negative'">{{ msg }}</q-item-section>
                            </q-item>
                        </div>
                    </b-form>
                </q-card>
            </b-col>
        </b-row>
    </div>
</div>
</template>

<script>
import {
    Header as AppHeader
} from '@coreui/vue'
import LangNavbarAuth from '@/views/auth/LangNavbarAuth';

export default {
    name: 'RespondToGroupInvitation',
    components: {
        AppHeader,
        'switch-lang': LangNavbarAuth
    },
    computed: {
        random_background: function () {
            var array = ['https://img5.goodfon.com/original/1920x1080/8/fd/sportivnaia-para-zal-trenazhernyi-zal-sport-tiaga-ganteli-pl.jpg', '/img/backgrounds/background_login.jpg']
            var random = this.$shuffle(array)

            return random[0]
        },
        container_background_image: function () {
            return 'background-image: url(' + this.random_background + ');'
        },
    },
    data: function () {
        return {
            msg: null,
            msg_status: false
        }
    },

    methods: {
        respondToInvitation: function (accepted) {
            var params = {
                email: this.$route.query['email'],
                invitation_token: this.$route.query['invitation_token'],
                accepted: accepted
            }
            axios.post(baseUrl + 'groups/members/respond-to-group-invitation', params)
                .then(response => {
                    this.msg = response.data.msg
                    if (response.data.status) {
                        this.msg_status = true
                    }
                })
                .catch(error => {
                    var msg = error.response.data.msg;
                    if (msg != undefined) {
                        this.msg = msg;
                    }
                })
        }
    }
}
</script>

<style scoped>
.header-lang {
    border-radius: 0.25rem
}
</style>
